import { Observer } from 'tailwindcss-intersect';
Observer.start();

function toggleMenu() {
    var activeNav = false;
    const navlinks = document.querySelector('.navlinks');
    
    if(navlinks.classList.contains('hidden') && !activeNav) {
        navlinks.classList.remove('hidden');
        activeNav = !activeNav;
    } else {
        navlinks.classList.add('hidden');
        activeNav = !activeNav; 
    }
}

const menuToggle = document.getElementById('menu-toggle');
menuToggle.addEventListener('click', toggleMenu);